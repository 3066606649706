export const {
  REACT_APP_PROXY_GITHUB_CALENDAR: PROXY_GITHUB_CALENDAR,
  REACT_APP_GITHUB_GRAPHQL: GITHUB_GRAPHQL,
  REACT_APP_GITHUB_LOGIN: GITHUB_LOGIN,
  REACT_APP_GITHUB_KEY: GITHUB_KEY,
} = process.env;

export const BOX_START_HEIGHT = 2.5
export const BOX_HEIGHT_MULTIPLIER = 2
export const BOX_SIZE_X = 8
export const BOX_SIZE_Y = 8
export const BOX_GAP_X = 1.5
export const BOX_GAP_Y = 1.5
