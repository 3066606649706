
import './Footer.css';
function Footer() {

    return (
        <div className='footer'>
            <div className="container">
                <p className={'footer__text'}>© Вадим Поляков 2019-2023 <span>@L0R39Z0</span></p>
            </div>

        </div>
    );
}

export default Footer